import {
  Link,
  Outlet,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import {
  IClient,
  IOrder,
  INotFoundProduct,
  IOrderBackorders,
  IOrderDetail,
  IOrderDetailCreate,
  ILocal,
} from '../../interfaces';
import OrderDetails from '../../components/Order/OrderDetails';
import OrderData from '../../components/Order/OrderData';
import {
  isEqualToOrder,
  isEqualToOrderDetails,
  isOrderDetailsValid,
  statusMappingFilters,
  updatedOrderDetails,
} from '../../lib/orders';
import { confirm } from '../../lib/utils';
import EditButtons from '../../components/EditButtons';
import OrderSummary from '../../components/Order/OrderSummary';
import { Button } from '@mui/material';
import NotFoundProducts from '../../components/Order/NotFoundProducts';
import Back from '../../components/Back';
import { IShipment } from '../../interfaces/IShipment';
import { GlobalContext } from '../../store';
import { alert } from '../../lib/utils';
import PageLoader from '../../components/PageLoader';

type ContextType = { local: ILocal };

const OrderShow = () => {
  const {
    api,
    user,
    context: { startLoading, finishLoading },
  } = useContext(GlobalContext);
  const { orderId } = useParams();
  const [order, setOrder] = useState<IOrder>();
  const [updatedOrder, setUpdatedOrder] = useState<IOrder>();
  const { local } = useOutletContext<ContextType>();
  const navigate = useNavigate();
  const [client, setClient] = useState<IClient | null>(null);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [orderBackorders, setOrderBackorders] = useState<IOrderBackorders>({});
  const [isOnHold, setIsOnHold] = useState<boolean>(false);
  const [shipment, setShipment] = useState<IShipment>();
  const [activeClient, setActiveClient] = useState<boolean>(false);
  const generateKey = (ProductId: number) => {
    return `${ProductId}_${new Date().getTime()}`;
  };

  useEffect(() => {
    (async () => {
      const order = await api.getOrder(local.id, Number(orderId));
      if (order === null) {
        navigate('../');
      }
      if (order) {
        const orderSetup = {
          ...order,
          OrderDetails: await Promise.all(
            order.OrderDetails.map(async (item) => {
              const product = await api.getProductById(item.ProductId);
              item.sku = product?.sku;
              item.name = product?.name;
              item.ReactComponentId = generateKey(item.ProductId);
              item.PackComponents = product.isPack
                ? await api.getPackComponents(item.ProductId)
                : [];
              item.isPack = product.isPack;
              return item;
            })
          ),
        };
        setOrder(orderSetup);
        setUpdatedOrder(orderSetup);
        if (order.activeShipment) {
          setShipment(order.activeShipment);
        }
        if (order.status === 'on-hold') {
          setIsOnHold(true);
          const backorders = await api.getOrderBackorders(order.id);
          if (backorders) {
            setOrderBackorders(backorders);
          }
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (!order || !order.ClientId) return;
    (async () => {
      const client = await api.getClientById(order.ClientId);
      setActiveClient(client.active);
    })();
  }, [order]);

  function reset() {
    setEditMode(false);
    window.location.reload();
  }

  async function handleAddOrderDetail(item: IOrderDetailCreate) {
    try {
      const product = await api.getProductById(item.ProductId);
      item.sku = product?.sku;
      item.name = product?.name;
      item.ReactComponentId = generateKey(item.ProductId);
      setUpdatedOrder((prev) => {
        if (prev) {
          return {
            ...prev,
            OrderDetails: [...prev.OrderDetails, item],
          };
        }
        return prev;
      });
    } catch (error) {
      if (error instanceof Error) {
        alert('error', error.message);
      }
    }
  }

  async function handleDeleteOrderDetail(item: IOrderDetail) {
    if (item.id === undefined) {
      setUpdatedOrder((prev) => {
        if (prev) {
          return {
            ...prev,
            OrderDetails: (prev.OrderDetails as IOrderDetail[]).filter(
              (x) => x.ReactComponentId !== item.ReactComponentId
            ),
          };
        }
        return prev;
      });
    } else {
      if (!(await confirm('Vas a eliminar un producto de la orden.'))) {
        return;
      }
      try {
        startLoading();
        await api.deleteOrderDetail(item.id as number);
        window.location.reload();
      } catch (error) {
        if (error instanceof Error) {
          alert('error', error.message);
        }
      }
    }
  }

  function showReshipButton(shipments: IShipment[]) {
    const lastShipmentIdx = shipments.map((sh, idx) => [sh.id, idx]).reduce(
      (r, a) => (a[0] > r[0] ? a : r)
    )[1];
    const lastShipment = order?.Shipments[lastShipmentIdx];
    return lastShipment?.status === 'returned' || lastShipment?.status === 'cancelled';
  }

  function showCancelOrderButton(shipments: IShipment[]) {
    if (!shipments.length) {
      return true;
    }
    const lastShipmentIdx = shipments.map((sh, idx) => [sh.id, idx]).reduce(
      (r, a) => (a[0] > r[0] ? a : r)
    )[1];
    const lastShipment = order?.Shipments[lastShipmentIdx] as IShipment;
    return ['pending', 'created', 'cancelled', 'returned'].includes(lastShipment?.status) 
    || lastShipment?.status.includes('Other status:')
  }

  if (!order || !updatedOrder) {
    return <PageLoader />;
  }

  const handleOrderSave = async () => {
    try {
      startLoading();
      // Save order data (left panel)
      if (!isEqualToOrder(updatedOrder, order)) {
        // make request
        const { OrderDetails, ...newOrderData } = updatedOrder; // eslint-disable-line
        await api.updateOrder(order.id, newOrderData);
      }

      // // save order details
      const updateableItems = updatedOrder.OrderDetails.filter(
        (item) => item.id !== undefined
      );
      const itemsToUpdate: Partial<IOrderDetail>[] = updatedOrderDetails(
        updateableItems,
        order.OrderDetails
      );
      const itemsToCreate = updatedOrder.OrderDetails.filter(
        (item) => item.id === undefined
      );
      if (itemsToUpdate.length || itemsToCreate.length) {
        // get only the items that were updated
        isOrderDetailsValid(updatedOrder.OrderDetails, ['TO', 'WO'].includes(order.OrderType.name));
        // make requests
        if (itemsToUpdate.length > 0) {
          if (statusMappingFilters.completed.includes(order.status)) {
            throw new Error(
              'No puedes editar los productos de un pedido completado.'
            );
          }
          await api.updateOrderDetails(itemsToUpdate);
        }
        await Promise.all(
          itemsToCreate.map((item) => api.createOrderDetail(item))
        );
      }

      setOrder({ ...order, ...updatedOrder });

      reset();
    } catch (error) {
      if (error instanceof Error) {
        alert('error', error.message);
      }
    }
    finishLoading();
  };

  const handleChangeNotFound = async (
    data: INotFoundProduct[]
  ): Promise<boolean> => {
    if (!(await confirm('Vas a actualizar los productos no encontrados.'))) {
      return false;
    }
    startLoading();
    try {
      await api.updateOrder(order.id, { notFoundProducts: data });
      setOrder({ ...order, notFoundProducts: data });
      finishLoading();
      return true;
    } catch (e) {
      if (e instanceof Error) {
        alert('error', e.message);
      }
      finishLoading();
      return false;
    }
  };

  const canCheckInvoiceButtonText = () => {
    if (order.ticketUrl.includes('http')) {
      if (order.status === 'cancelled') return 'Ver Nota de crédito';
      return  order.OrderType.name === 'B2B' ? 'Obtener factura' :'Ver boleta';
    }
    return order.OrderType.name === 'B2B' ? 'Error factura' : 'Error boleta';
  };

  const printInvoiceButtonText = () => {
    if (!order.ticketUrl) {
      return 'Finalizar Pedido';
    }
    return canCheckInvoiceButtonText();
  };

  const handleOrderPrintInvoice = async () => {
    try {
      let confirmed = true;
      if (order.status === 'cancelled') {
        window.open(order.ticketUrl, '_blank');
        return;
      }
      if (!order.ticketUrl) {
        confirmed = await confirm(
          'Vas a finalizar el pedido y crear la boleta.'
        );
      }
      if (confirmed) {
        window.open(`${order.invoiceSignedUrl}&checkFulfilled=true`, '_blank');
      }
    } catch (error) {
      if (error instanceof Error) {
        alert('error', error.message);
      }
    }
  };

  const printInvoiceButtonDisabled = () => {
    const ticketError = !!(
      order.ticketUrl && !order.ticketUrl?.includes('http')
    );
    return ticketError;
  };

  const handleOrderViewLabel = async () => {
    if (shipment?.labels) {
      shipment.labels.map((url) => window.open(url, '_blank'));
    }
  };

  function handleUpdatedOrderDataChange(orderData: Partial<IOrder>) {
    setUpdatedOrder((order) => {
      if (order) {
        return {
          ...order,
          ...orderData,
        };
      }
      return order;
    });
  }

  function handleUpdateOrderDetail(
    key: string,
    id: string | number | symbol,
    value: number | string
  ) {
    setUpdatedOrder((prevOrder) => {
      if (prevOrder) {
        const newOrderDetails = prevOrder.OrderDetails.map((item) => {
          if (item.ReactComponentId === key) {
            return {
              ...item,
              [id]: value || null,
            };
          }
          return item;
        });
        return {
          ...prevOrder,
          OrderDetails: newOrderDetails,
        };
      }
      return prevOrder;
    });
  }

  return (
    <>
      <Back />
      <div className="OrderContainer">
        <OrderData
          operation="edit"
          order={updatedOrder}
          setOrderData={handleUpdatedOrderDataChange}
          client={client}
          setClient={setClient}
          editMode={editMode}
          isInternalOrder={['TO', 'WO'].includes(order.OrderType.name)}
          selectedOrderType={order.OrderType}
        >
          <>
            {!activeClient && order.ticketUrl && (
              <div className="Row Center EditButtons">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOrderPrintInvoice}
                  disabled={printInvoiceButtonDisabled()}
                >
                  {canCheckInvoiceButtonText()}
                </Button>
              </div>
            )}
            {activeClient && (
              <div className="Row Center EditButtons">
                <Button
                  variant="contained"
                  onClick={handleOrderPrintInvoice}
                  disabled={printInvoiceButtonDisabled()}
                  color='primary'
                  sx={order.OrderType.name === 'B2B' ? { fontWeight: 'bold' } : {}}
                >
                  {printInvoiceButtonText()}
                </Button>
              </div>
            )}
          </>

          <>
            {shipment?.labels && shipment.labels.length > 0 && (
              <div className="Row Center EditButtons">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleOrderViewLabel}
                >
                  Ver etiqueta de envío
                </Button>
              </div>
            )}
          </>

          <>
            {order.status === 'fulfilled' &&
              shipment?.status &&
              order.shippingType === 'pickup' && (
                <div className="Row Center EditButtons">
                  {shipment.status !== 'delivered' ? (
                    <Link to={'pickup'}>
                      <Button variant="contained" color="secondary">
                        Entregar Pickup
                      </Button>
                    </Link>
                  ) : (
                    <Button variant="contained" disabled>
                      Pickup Entregado
                    </Button>
                  )}
                </div>
              )}
          </>
          <>
            {activeClient && (
            <>
              {order.status === 'fulfilled' &&
                order.Shipments.length &&
                showReshipButton(order.Shipments)
                ? (
                  <div className="Row Center EditButtons">
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => navigate('reship')}
                    >
                      Re-pedir envío
                    </Button>
                  </div>
                ) : <></>
              }
              {order.activeShipment && (['pending', 'created', 'delivered'].includes(order.activeShipment.status) 
                || order.activeShipment.status.includes('Other status:')) 
              && (
                <div className="Row Center EditButtons">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate('finishShipment')}
                  >
                    Finalizar Envío
                  </Button>
                </div>
              )}
              {order.status !== 'fulfilled' &&
                order.activeShipment?.status === 'pending' && (
                  <div className="Row Center EditButtons">
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => navigate('reallocate')}
                    >
                      Cambiar local
                    </Button>
                  </div>
              )}
              {showCancelOrderButton(order.Shipments) && (
                <div className="Row Center EditButtons">
                  <Button
                    variant="contained"
                    color="warning"
                    onClick={() => navigate('cancel')}
                    disabled={order.status === 'cancelled'}
                  >
                    Cancelar Pedido
                  </Button>
                </div>
              )}
            </>
          )}
        </>
        </OrderData>
        <OrderDetails
          order={updatedOrder}
          editMode={editMode}
          onAddOrderDetail={handleAddOrderDetail}
          onUpdateOrderDetail={handleUpdateOrderDetail}
          pointOfSaleId={
            updatedOrder.PointOfSaleId || order.PointOfSaleId || null
          }
          isOnHold={isOnHold}
          backorders={orderBackorders}
          onDeleteOrderDetail={handleDeleteOrderDetail}
        >
          <NotFoundProducts
            data={order.notFoundProducts}
            onChange={handleChangeNotFound}
          />

          <OrderSummary
            order={updatedOrder}
            setOrderData={handleUpdatedOrderDataChange}
            editMode={editMode}
          />
          <>
            {activeClient && (
              <EditButtons
                saveDisabled={
                  isEqualToOrder(updatedOrder, order) &&
                  isEqualToOrderDetails(
                    updatedOrder.OrderDetails,
                    order.OrderDetails
                  )
                }
                editDisabled={
                  statusMappingFilters.completed.includes(order.status) &&
                  user.role !== 'admin'
                }
                editMode={editMode}
                onCancel={reset}
                onEdit={() => setEditMode(true)}
                onSave={handleOrderSave}
              />
            )}
          </>
          <Outlet context={{ order }} />
        </OrderDetails>
      </div>
    </>
  );
};

export default OrderShow;
