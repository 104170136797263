import {
  BooleanInput,
  Create,
  ReferenceInput,
  SelectInput,
  SimpleForm,
} from 'react-admin';

export const PointOfSaleCreate = () => (
  <Create>
    <SimpleForm>
      <ReferenceInput source="IntegrationId" reference="integrations">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        source="ClientId"
        reference="clients"
        sort={{ field: 'name', order: 'ASC' }}
        perPage={10000}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput source="CountryId" reference="countries">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <BooleanInput source="hasExpressTab" />
      <ReferenceInput
          source="principalLocalId"
          reference="locals"
          sort={{ field: 'name', order: 'ASC' }}
          perPage={1000}
        >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        source="OrderTypeId"
        reference="order-types"
        filter={{ id: [1,2,3,4] }}
        sort={{ field: 'name', order: 'ASC' }}
        perPage={10000}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);
