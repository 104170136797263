import {
  BooleanField,
  Datagrid,
  DateField,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
} from 'react-admin';
import { AdminPagination } from '../AdminLayout';

const chargeRateFilters = [
  <ReferenceInput
    source="ClientId"
    reference="clients"
    sort={{ field: 'name', order: 'ASC' }}
    perPage={1000}
    alwaysOn
  >
    <SelectInput optionText="name" />
  </ReferenceInput>,
  <ReferenceInput
    source="OrderTypeId"
    reference="order-types"
    sort={{ field: 'name', order: 'ASC' }}
    filter={{ id: [1,2,3,4] }}
    perPage={1000}
    alwaysOn
  >
    <SelectInput optionText="name" />
  </ReferenceInput>,
];

export const ChargeRateList = () => (
  <List filters={chargeRateFilters} pagination={<AdminPagination />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <ReferenceField source="ClientId" reference="clients">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="OrderTypeId" reference="order-types">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="type" />
      <BooleanField source="isDefault" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);
