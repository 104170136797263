import { BooleanInput, Create, ReferenceInput, SelectInput, SimpleForm } from 'react-admin';

export const ChargeRateCreate = () => (
  <Create>
    <SimpleForm>
      <ReferenceInput
        source="ClientId"
        reference="clients"
        sort={{ field: 'name', order: 'ASC' }}
        perPage={10000}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        source="OrderTypeId"
        reference="order-types"
        filter={{ id: [1,2,3,4] }}
        sort={{ field: 'name', order: 'ASC' }}
        perPage={10000}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <SelectInput
        source="type"
        choices={[
          { id: 'Order', name: 'Order' },
          { id: 'ShipmentPackage', name: 'ShipmentPackage' },
          { id: 'OrderDetail', name: 'OrderDetail' },
        ]}
        defaultValue={'Order'}
      />
      <BooleanInput source="isDefault" defaultValue={true} />
    </SimpleForm>
  </Create>
);
