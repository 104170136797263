import {
  Datagrid,
  DateField,
  List,
  NumberField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
} from 'react-admin';
import { JsonField } from 'react-admin-json-view';
import { AdminPagination } from '../AdminLayout';

const orderFilters = [
  <ReferenceInput
    source="ClientId"
    reference="clients"
    sort={{ field: 'name', order: 'ASC' }}
    perPage={1000}
    alwaysOn
  >
    <SelectInput optionText="name" />
  </ReferenceInput>,
  <NumberInput label="Id" source="id" alwaysOn />,
  <TextInput label="Platform Code" source="platformCode" />,
  <TextInput label="Code" source="code" />,
  <TextInput label="Customer Name" source="customerName" />,
  <TextInput label="Customer Email" source="customerEmail" />,
  <ReferenceInput
    source="OrderTypeId"
    reference="order-types"
    sort={{ field: 'name', order: 'ASC' }}
    filter={{ id: [1,2,3,4] }}
    perPage={1000}
    alwaysOn
  >
    <SelectInput optionText="name" />
  </ReferenceInput>,
];

export const OrderList = () => (
  <List
    filters={orderFilters}
    sort={{ field: 'id', order: 'DESC' }}
    pagination={<AdminPagination />}
  >
    <Datagrid rowClick="edit">
      <TextField source="customerName" />
      <TextField source="id" />
      <TextField source="internalCode" />
      <NumberField source="OriginalOrderId" />
      <DateField source="date" />
      <TextField source="code" />
      <TextField source="platformCode" />
      <TextField source="customerEmail" />
      <TextField source="note" />
      <JsonField
        source="shippingAddress"
        jsonString={false} // Set to true if the value is a string, default: false
        reactJsonOptions={{
          // Props passed to react-json-view
          name: null,
          collapsed: true,
          enableClipboard: false,
          displayDataTypes: true,
        }}
      />
      <TextField source="commune" />
      <TextField source="ticketInfo" />
      <TextField source="ticketUrl" />
      <TextField source="status" />
      <TextField source="pat" />
      <NumberField source="rapiboyOrderId" />
      <NumberField source="globalDiscount" />
      <TextField source="shippingType" />
      <NumberField source="shippingDiscount" />
      <TextField source="shippingCost" />
      <TextField source="customerPhone" />
      <TextField source="notFoundProducts" sortable={false} />
      <TextField source="statusHistory" sortable={false} />
      <ReferenceField source="ClientId" reference="clients">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="LocalId" reference="locals">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="CommuneId" reference="communes">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="IntegrationId" reference="integrations">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <ReferenceField source="PointOfSaleId" reference="pos">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="ChargeRateId" reference="charge-rates">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="OrderTypeId" reference="order-types">
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
);
