import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { IClient, ILocal, IPointOfSale } from '../../interfaces';
import { GlobalContext } from '../../store';
import { alert, capitalize } from '../../lib/utils';
import { IInvokeLambda } from '../../interfaces/IInvokeLambda';
import { IClientLocals } from '../../interfaces/IClientLocals';

const Files = ({ client }: { client: IClient }) => {
  const [stockSource, setSourceStock] = useState<'bsale' | 'shopify' | ''>('');
  const [addedLocals, setAddedLocals] = useState<ILocal[]>([]);
  const [posList, setPosList] = useState<IPointOfSale[]>([]);
  const [posId, setPosId] = useState<number | null>(null);
  const [clientLocals, setClientLocals] = useState<IClientLocals[]>([]);
  const stockSources = ['shopify', 'bsale'];

  // Context
  const { context, api, functionsApi } = useContext(GlobalContext);
  const { startLoading, finishLoading } = context;

  function handleDeleteLocal(idx: number) {
    const updatedLocals = addedLocals.filter((_, index) => index !== idx);
    setAddedLocals(updatedLocals);
  }

  function handleAddLocal(event: ChangeEvent<HTMLSelectElement>) {
    const {
      target: { value },
    } = event;
    const clientLocal = clientLocals.find((cl) => cl.id === Number(value));
    if (clientLocal && !addedLocals.includes(clientLocal.Local)) {
      setAddedLocals([...addedLocals, clientLocal.Local]);
    }
  }

  function resetParams() {
    setSourceStock('');
    setClientLocals([]);
    setPosId(null);
    setAddedLocals([]);
  }

  async function handleSubmit() {
    if (
      !client ||
      !stockSource ||
      addedLocals.length === 0 ||
      (stockSource === 'shopify' && posId === null)
    ) {
      alert('error', 'Formulario incompleto');
      return;
    }
    const requestBody: IInvokeLambda = {};
    const locals = addedLocals.map((loc) => ({
      id: Number(loc.id),
      localExternalId: loc.stockSourceId,
    }));
    switch (stockSource) {
      case 'bsale':
        requestBody.functionName = 'GenerateBSaleStock';
        requestBody.payload = {
          clientId: client.id,
          locals,
        };
        break;
      case 'shopify':
        if (posId) {
          requestBody.functionName = 'GenerateInitialStock';
          requestBody.payload = {
            posId: posId,
            locals,
          };
        }
        break;
      default:
        return;
    }
    try {
      startLoading();
      await functionsApi.invokeLambda(requestBody);
      finishLoading();
      alert('success', 'La solicitud se envió correctamente');
      resetParams();
    } catch (error) {
      finishLoading();
      alert('error', `La solicitud no se envió correctamente: ${error}`);
    }
  }

  useEffect(() => {
    (async () => {
      if (stockSource === 'shopify') {
        const integration = (await api.getIntegrations(client.id)).find(
          (integration) => integration.name === stockSource
        );
        const pos = await api.getPOS(client.id);
        const result = pos.filter((p) => p.IntegrationId === integration?.id);
        setPosList(result);
      }
      const response = await api.getClientLocals(client.id);
      setClientLocals(response);
    })();
  }, [stockSource]);

  return (
    <>
      <div className="InputWrapper">
        <div className="InputTitle">Fuente de stock</div>
        <select
          name="integration"
          onChange={(e) =>
            setSourceStock(e.target.value as 'bsale' | 'shopify' | '')
          }
        >
          <option value="" selected={stockSource === ''}>
            Seleccionar...
          </option>
          {stockSources.map((opt) => (
            <option value={opt} key={opt} selected={stockSource === opt}>
              {capitalize(opt)}
            </option>
          ))}
        </select>
      </div>
      {stockSource === 'shopify' && (
        <div className="InputWrapper">
          <div className="InputTitle">Elige el Pos</div>
          <select name="pos" onChange={(e) => setPosId(Number(e.target.value))}>
            <option value="" selected={posId === null}>
              Seleccionar...
            </option>
            {posList.map((opt) => (
              <option value={opt.id} key={opt.id} selected={posId === opt.id}>
                {capitalize(opt.name)}
              </option>
            ))}
          </select>
        </div>
      )}
      {stockSource && (
        <div className="InputWrapper">
          <div className="InputTitle">Agrega los locales</div>
          <div className="InlineDisplay">
            <div className="InputWrapper">
              <select name="selectedLocal" onChange={(e) => handleAddLocal(e)}>
                <option value="">Seleccionar...</option>
                {clientLocals.map((opt) => (
                  <option value={opt.id} key={opt.id}>
                    {capitalize(opt.Local.name)}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      )}
      {addedLocals.length !== 0 && (
        <>
          <div className="MinimizeTitle">Locales Agregados</div>
          <div className="AddedLocals">
            {addedLocals.map((local, index) => (
              <div key={local.id}>
                <div className="InlineDisplay AllRow">
                  <div key={local.id}>
                    <span>Local:</span> {capitalize(local.name)}
                  </div>
                  <div
                    className="Button Secondary"
                    onClick={() => handleDeleteLocal(index)}
                  >
                    Eliminar
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      <div
        className={`Button ${
          !client || addedLocals.length === 0 ? 'disabled' : ''
        }`}
        onClick={handleSubmit}
      >
        Enviar
      </div>
    </>
  );
};

export default Files;
