import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
} from 'react-admin';
import { AdminPagination } from '../AdminLayout';

const clientLocalFilters = [
  <ReferenceInput
    source="ClientId"
    reference="clients"
    sort={{ field: 'name', order: 'ASC' }}
    perPage={10000}
  >
    <SelectInput optionText="name" />
  </ReferenceInput>,
  <ReferenceInput
    source="LocalId"
    reference="locals"
    sort={{ field: 'name', order: 'ASC' }}
    perPage={10000}
  >
    <SelectInput optionText="name" />
  </ReferenceInput>,
];

export const ClientLocalList = () => (
  <List filters={clientLocalFilters} pagination={<AdminPagination />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <ReferenceField source="ClientId" reference="clients">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="LocalId" reference="locals">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="pickerUrl" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);
