import {
  DateField,
  Edit,
  FormDataConsumer,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  useRecordContext,
} from 'react-admin';
import ReactJson from 'react-json-view';
import { transformInput } from '../../../lib/utils';
import { useState } from 'react';

const StatusHistoryRecord = () => {
  const record = useRecordContext();
  // the record can be empty while loading
  if (!record) {
    return <p />;
  }
  return (
    <ReactJson name="statusHistory" src={record.statusHistory} collapsed />
  );
};

export const ShipmentEdit = () => {
  const [courierId, setCourierId] = useState<number | null>(null);

  return (
    <Edit transform={transformInput}>
      <SimpleShowLayout>
        <TextField source="id" />
        <DateField source="createdAt" showTime />
        <DateField source="updatedAt" showTime />
        <StatusHistoryRecord />
      </SimpleShowLayout>
      <SimpleForm>
        <NumberInput source="OrderId" />
        <ReferenceInput source="CourierId" reference="couriers">
        <SelectInput
            onChange={(e) => {
              setCourierId(e.target.value);
            }}
            optionText="name"
          />
        </ReferenceInput>
        <TextInput source="externalId" />
        <TextInput source="trackingUrl" />
        <FormDataConsumer>
          {({ formData }) =>
            !!formData.CourierId && (
              <>
                {setCourierId(formData.CourierId)}
                {[7, 12].includes(courierId as number) ? (
                  <TextInput source="status" />
                ) : (
                  <TextField source="status" />
                )}
              </>
            )
          }
        </FormDataConsumer>
        <DateField source="eta" />
        <NumberInput source="fee" />
        <NumberInput source="messageFee" />
        <p>Campos opcionales</p>
        <TextInput source="receiverInfo.name" />
        <TextInput source="receiverInfo.identifier" />
        <TextInput source="receiverInfo.image" />
        <TextInput source="receiverInfo.relationship" />
      </SimpleForm>
    </Edit>
  );
};
