import {
  BooleanInput,
  DateField,
  Edit,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
} from 'react-admin';
import { transformInput } from '../../../lib/utils';

export const StockMoveEdit = () => (
  <Edit transform={transformInput}>
    <SimpleShowLayout>
      <TextField source="id" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </SimpleShowLayout>
    <SimpleForm>
      <NumberInput source="available" />
      <NumberInput source="onHand" />
      <TextInput source="processed" />
      <TextInput source="reason" />
      <TextInput source="source" />
      <BooleanInput source="activated" />
      <ReferenceInput source="ProductId" reference="products">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput source="LocalId" reference="locals">
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);
