import { useContext, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { ILocal, ICustomChangeEvent, IOrder } from '../../interfaces';
import EditButtons from '../../components/EditButtons';
import { GlobalContext } from '../../store';
import AutocompleteInput from '../../components/Order/AutocompleteInput';
import { alert } from '../../lib/utils';

type ContextType = { order: IOrder; local: ILocal };

const OrderReallocate = () => {
  const { api, context } = useContext(GlobalContext);
  const { order, local } = useOutletContext<ContextType>();

  const [newLocal, setNewLocal] = useState<ILocal>(local);
  const navigate = useNavigate();

  async function handleSubmit() {
    context.startLoading();
    try {
      await api.reallocateOrder(order.id, { localId: newLocal.id });
      navigate('../');
      window.location.reload();
    } catch (error) {
      if (error instanceof Error) {
        alert('error', error.message);
      }
    }
    context.finishLoading();
  }

  function handleLocalChange(event: ICustomChangeEvent) {
    setNewLocal(event.target.item as ILocal);
  }

  return (
    <>
      <div className="FormBorder">
        <h2 className="Row Center">Cambiar Local</h2>
        <div className="OrderContainer Center">
          <form>
            <AutocompleteInput
              label="Local"
              dataKey="name"
              allWidth={true}
              fetchData={async () =>
                api.getLocals()
              }
              onChange={handleLocalChange}
              defaultValue={local}
            />
            <EditButtons
              editMode
              onCancel={() => navigate('../')}
              onSave={handleSubmit}
            />
          </form>
        </div>
      </div>
    </>
  );
};

export default OrderReallocate;
